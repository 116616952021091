import React, { useState, useEffect } from "react";
// @material-ui/icons
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import NavPills from "components/NavPills/NavPills";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PackageHistory from "views/Tables/PackageHistory";
import axios from "axios";
import '../../assets/css/style.css'
import { useParams } from "react-router-dom";
import NA_InfoTable from "../NotAdminTables/NA_InfoTable";
import OrdersTable from '../Tables/OrdersTable'
import Activesub from "../Forms/ActiveSub";
import ManualHistoryTable from "views/Tables/ManualHistoryTable";
import Card from "components/Card/Card";

export default function NA_UserDetail() {
  let { id } = useParams()
  const [datas, setDatas] = useState({})
  const [packageDatas, setPackageDatas] = useState({})
  const [activePackage, setActivePackage] = useState()
  const [sub, setSub] = useState()
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [status, setStatus] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()

  //Pages++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  function clickPage(page) {
    setLoadingStatus(true)
    axios.post(`${process.env.REACT_APP_API}/form/usersearchhistory?page=${page}/`, { username: id }, {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setPackageDatas(result.data.result)
      setTotalPages(result.data.totalPages)
      setPage(page)
    })
      .finally(() => {
        setLoadingStatus(false)
        setStatus(true)
      })
  }
  //Pages-----------------------------------------------------------------

  function preRender() {
    axios.post(`${process.env.REACT_APP_API}/data/package/?id=${id}`, 'index', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setSub(result.data.sub)
      setActivePackage(result.data.result)
    }).finally(() => setLoadingStatus(false))
  }

  useEffect(() => {
    if (!window.localStorage.getItem('userAccessToken')) {
      window.location.href = `http://${window.location.host}/auth/`
    }
    axios.post(`${process.env.REACT_APP_API}/data/package/?id=${id}`, 'index', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setSub(result.data.sub)
      setActivePackage(result.data.result)
    }).finally(() => {
      axios.post(`${process.env.REACT_APP_API}/form/usersearchhistory?page=1/`, { username: id }, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(result => {
        setPackageDatas(result.data.result) // packagehistory
        setTotalPages(result.data.totalPages)
        setPage(1)
      })
        .finally(() => {
          setLoadingStatus(false)
          setStatus(true)
        })
    })
    axios.post(`${process.env.REACT_APP_API}/form/searchuser/detail/${id}`, 'index', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data)
    })
  }, [])
  return (
    <GridContainer>
      <GridItem xs={12}>
        {
          loadingStatus == true && status == false &&
          <CircularProgress color="secondary" className='loading' />
        }
        <Card>
          <GridContainer
            justify="space-between"
            alignItems="center"
            direction="row"
          >
            <GridItem >
              <div className='bold info'><h3>User ID: {datas[0]?.user_id}</h3></div>
            </GridItem>
            <GridItem >
              <div className='bold info'><h3>Username: {datas[0]?.username}</h3></div>
            </GridItem>
            <GridItem >
              <div className='bold info'><h3>Email: {datas[0]?.email}</h3></div>
            </GridItem>
          </GridContainer>
        </Card>
        {status === true && packageDatas &&
          <NavPills
            color="success"
            tabs={[
              {
                tabButton: "Info",
                tabContent: (
                  <NA_InfoTable
                    id={id}
                  />
                )
              },
              {
                tabButton: "Package History",
                tabContent: (
                  <PackageHistory
                    loadingStatus={loadingStatus}
                    headers={['ref No.', 'Pack name', 'Transaction Date', 'Expire Date']}
                    datatable={packageDatas}
                    activePackage={activePackage}
                    sub={sub}
                    id={id}
                    page={page}
                    totalPages={totalPages}
                    clickPage={clickPage}
                  />
                ),
              },
              {
                tabButton: "Orders",
                tabContent: (
                  <OrdersTable
                    id={id}
                  />
                ),
              },
              {
                tabButton: "Active Subscription",
                tabContent: (
                  <Activesub
                    id={id}
                    loadingStatus={loadingStatus}
                    activePackage={activePackage}
                    sub={sub}
                    preRender={preRender}
                  />
                ),
              },
              {
                tabButton: "Manual History",
                tabContent: (
                  <ManualHistoryTable
                    id={id}
                  />
                ),
              },
            ]}
          />
        }
      </GridItem>
    </GridContainer >
  );
}
