import React, { useState, useEffect } from "react";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import CircularProgress from '@material-ui/core/CircularProgress';
// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import axios from "axios";


export default function InfoTable(props) {
  const { id } = props
  const [datas, setDatas] = useState({})
  const [loading, setLoading] = useState(true)

  function changeStatus(status, textStatus) {
    if (window.confirm("Change Status ?")) {
      axios.patch(`${process.env.REACT_APP_API}/form/changestatus/${id}`, { active: status }, {
        headers: {
          authorization: window.localStorage.getItem('userAccessToken')
        }
      }).then(() => {
        //location.reload()
        setDatas(
          datas.map((data) => {
            return {
              active: status,
              user_id: data.user_id,
              username: data.username,
              email: data.email,
              user_phone: data.user_phone,
              fb_detail: data.fb_detail,
              gg_detail: data.gg_detail,
              line_detail: data.line_detail,
              ap_detail: data.ap_detail,
              refer_code: data.refer_code,
              mc_address: data.mc_address,
              version: data.version,
              device: data.device,
              ip: data.ip,
              create_date: new Date(datas[0].create_date).toLocaleString(),
              updated_at: new Date(datas[0].updated_at).toLocaleString()
            }
          })
        )
      }).finally(() => document.getElementById('status').innerHTML = textStatus)
    }
  }

  function renderEmailButton() {
    if (datas.length > 0 && loading === false && datas[0].fb_detail !== 'facebook' && datas[0].gg_detail !== 'google' && datas[0].line_detail !== 'line' && datas[0].ap_detail !== 'apple') {
      return (
        <Button color='info' simple>
          <i
            className={
              " fas fa-envelope"
            }
          />
        </Button>
      )
    } else {
      return (
        <Button color='shadow' simple>
          <i
            className={
              " fas fa-envelope"
            }
          />
        </Button>
      )
    }
  }
  useEffect(() => {
    axios.post(`${process.env.REACT_APP_API}/form/searchuser/detail/${id}`, 'index', {
      headers: {
        authorization: window.localStorage.getItem('userAccessToken')
      }
    }).then(result => {
      setDatas(result.data)
    })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  return (
    <>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {loading == true &&
            <CircularProgress color="secondary" className='loading' />
          }
          <hr />
          <span className='bold info'>Subscription: </span>
          {
            datas.length > 0 && loading === false &&
              datas[0].expire_sub >= new Date().toISOString()
              ? <span className='info'><Button id='status' color='success' size="sm" >Active</Button></span>
              : <span className='info'><Button id='status' color='danger' size='sm' >Inactive</Button></span>
          }
          <br />
          <hr />
          <span className='bold info'>Package: </span>
          {
            datas.length > 0 && loading === false &&
              datas[0].expire_pack >= new Date().toISOString()
              ? <span className='info'><Button id='status' color='success' size="sm" >Active</Button></span>
              : <span className='info'><Button id='status' color='danger' size='sm' >Inactive</Button></span>
          }
          <br />
          <hr />
          <span className='bold info'>USER ID: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{datas[0].user_id}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Username: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{datas[0].username}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Email: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{datas[0].email}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Phone: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{datas[0].user_phone}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Link: </span>
          {renderEmailButton()}
          {
            datas.length > 0 && loading === false && datas[0].fb_detail &&
            <Button color='facebook' simple>
              <i
                className={
                  " fab fa-facebook-square"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && datas[0].fb_detail != 'facebook' &&
            <Button color='shadow' simple>
              <i
                className={
                  " fab fa-facebook-square"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && loading === false && datas[0].gg_detail &&
            <Button color='google' simple>
              <i
                className={
                  " fab fa-google"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && datas[0].gg_detail != 'google' &&
            <Button color='shadow' simple>
              <i
                className={
                  " fab fa-google"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && loading === false && datas[0].line_detail &&
            <Button color='success' simple>
              <i
                className={
                  "fab fa-line"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && datas[0].line_detail != 'line' &&
            <Button color='shadow' simple>
              <i
                className={
                  "fab fa-line"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && loading === false && datas[0].ap_detail &&
            <Button color='github' simple>
              <i
                className={
                  "fab fa-apple"
                }
              />
            </Button>
          }
          {
            datas.length > 0 && datas[0].ap_detail != 'apple' &&
            <Button color='shadow' simple>
              <i
                className={
                  "fab fa-apple"
                }
              />
            </Button>
          }
          <br />
          <hr />
          <span className='bold info'>Version: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{datas[0].version}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Device: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{datas[0].device}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Create date: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{new Date(datas[0].create_date).toLocaleString('th')}</span>
          }
          <br />
          <hr />
          <span className='bold info'>Last Update: </span>
          {
            datas.length > 0 && loading === false &&
            <span className='info'>{new Date(datas[0].updated_at).toLocaleString('th')}</span>
          }
          <br />
          <hr />
        </CardBody>
      </Card>
    </>
  );
}
